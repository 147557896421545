import React, { FC } from 'react';

type StarProps = {
    numberOfStar: number
}

const Star: FC<StarProps> = ({ numberOfStar }) => {
    const widthPercent = ((numberOfStar / 5) * 100).toFixed(3);

    return (
        <div
            className="flex justify-items-start w-[75px] h-[15px]"
            style={{ backgroundImage: 'url(/emptyStar.svg)', backgroundSize: '15px 15px' }}
        >
            <div
                style={{ backgroundImage: 'url(/star.svg)', backgroundSize: '15px 15px', width: `${widthPercent}%` }}
            />
        </div>
    );
};

export default Star;
