import { AvisVerifie } from '@homebox/client';
import dayjs from 'dayjs';
import * as locale from 'dayjs/locale/fr';
import Link from 'next/link';
import React, { FC, HTMLAttributes } from 'react';
import Star from '@/ui/components/star';
import Typography from '@/ui/components/typography';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';

interface QuoteReviewProps extends HTMLAttributes<HTMLDivElement> {
    quote: AvisVerifie,
    isInSlider?: boolean
}

const QuoteReview: FC<QuoteReviewProps> = ({ quote, className = '', isInSlider = false }) => {
    const dateFormat = dayjs(quote.date).locale(locale).format('DD MMMM YYYY');
    const translateRoute = useRouteTranslator();

    return (
        <div className={className}>
            <a>
                {quote.center?.name
                    ? (
                        <Link href={`${translateRoute('LIST_SELFSTORAGE', { slug: quote.center.slug })}`}>
                            <a>
                                <Typography className="mb-1" variant="h6">{quote.center?.name}</Typography>
                            </a>
                        </Link>
                    ) : null}
            </a>
            <div className="flex items-center mb-2">
                <Star numberOfStar={quote.rate} />
                <Typography className="ml-2" variant="tiny">{quote.rate}/5</Typography>
            </div>
            <Typography
                className={isInSlider ? 'h-[120px]' : ''}
                style={{
                    WebkitLineClamp: '5',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                }}
                variant="normal"
            >{quote.description}
            </Typography>
            <Typography className="mt-4" variant="h6">{`${quote.firstname || ''} ${quote.lastname || ''}`}</Typography>
            <Typography variant="tiny">{dateFormat}</Typography>
        </div>
    );
};

export default QuoteReview;
